<template>
  <div>
    <h1>公司大门</h1>
    <button class="round-button" :style="{ backgroundColor: buttonColor }"  @click="openDoor(1706452)">
      <span class="button-text">{{ buttonText }}</span>
    </button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      buttonText: '开门' // 初始按钮文本
    };
  },
  methods: {
    openDoor(deviceId) {
      console.log(`设备 ${deviceId} 正在开门...`);
      // 改变按钮文本为“正在开门”
      this.buttonText = '正在开门';
       this.buttonColor = '#FF0000'; // 改变颜色为红色
      
      axios.post('http://zhaoc.top:8090/openDoor', null, {
        params: { deviceId: deviceId }
      })
      .then(response => {
        console.log('开门成功:', response);
        alert("开门成功，请确认！");
        // 恢复按钮文本
        this.buttonText = '开门';
        this.buttonColor = '#42b983'; // 恢复原颜色
      })
      .catch(error => {
        alert("开门失败！");
        console.error('开门失败:', error);
         // 恢复按钮文本
        this.buttonText = '开门';
        
        this.buttonColor = '#42b983'; // 恢复原颜色
      });
    }
  }
};
</script>

<style scoped>
h1 {
  color: #42b983;
  text-align: center;
}

.round-button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #42b983;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  flex-direction: column; /* 垂直排列内容 */
  margin: 0 auto; /* 中心对齐按钮 */
}

.round-button:hover {
  background-color: #369f75;
}

.round-button svg {
  width: 50px; /* 调整 SVG 图标的大小 */
  height: 50px;
}

.button-text {
  font-size: 16px; /* 设置文本大小 */
  color: white;
  text-align: center; /* 确保文本在其容器内居中 */
}
</style>
